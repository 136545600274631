import { FaSignInAlt } from "react-icons/fa";

import { Signout } from './signout.js';

export function Navbar({
    page,
    setPagePlan,
    setPageSimulate,
    signedIn,
    setSignedIn,
    openHelpModal,
}) {
    return (
        <div id="navbar">
            <div id="navbar-header">
                {signedIn
                  ? <img src="wordmark-color.svg" width="160" alt="logo"/>
                  : <a href="https://syrplus.io">
                        <img src="wordmark-color.svg" width="160" alt="logo"/>
                    </a>}
            </div>
            <div id="navbar-body">
                <NavbarButton
                    selected={page === 'plan'}
                    action={setPagePlan}
                >PLAN
                </NavbarButton>
                <NavbarButton
                    selected={page === 'simulate'}
                    action={setPageSimulate}
                >SIMULATE
                </NavbarButton>
                <button onClick={openHelpModal}>HELP</button>
            </div>
            <div id="navbar-footer">
                {signedIn
                  ? <Signout setSignedIn={setSignedIn}/>
                  : <button onClick={(e) => {
                        window.location.href = "https://syrplus.io";
                    }}><FaSignInAlt
                        class="button-icon"
                    />&nbsp;&nbsp;Sign in</button>}
            </div>
        </div>
    );
}

function NavbarButton({action, selected, children}) {
    return (
        <button class={selected ? 'selected' : ''} onClick={action}>
            {children}
        </button>
    );
}
