import React, { useState } from 'react';

export function NumericalInput({value, setValue}) {
    const [tempValue, setTempValue] = useState(value);
    const [isValidTempValue, setIsValidTempValue] = useState(true);

    const isValid = (val) => /^-?\d*(\.\d+)?$/.test(val);

    const handleChange = (e) => {
        if (isValid(e.target.value)) {
            setValue(Number(e.target.value));
        }
        setTempValue(e.target.value);
        setIsValidTempValue(isValid(e.target.value));
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Escape' || e.key === 'Enter') {
            setTempValue(value);
            setIsValidTempValue(true);
            e.target.blur();
        }
    }

    return (
        <input
            type="text"
            value={tempValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            style={isValidTempValue ? {} : { borderColor: 'red' }}
        />
    );
}
