export function DemoBanner() {
    return (
        <div id="demo-banner">
            <div id="banner-left">
            </div>
            <div id="banner-mid">
                You're using a free demo of Syrplus
            </div>
            <div id="banner-right">
                Premium coming soon!
                😀
                <a href="https://syrplus.io">join the waitlist</a>
            </div>
        </div>
    );
}
