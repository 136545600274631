import { FaTimes } from "react-icons/fa";

export function Modal({close, title, body}) {
    window.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
            closeHandler();
        }
    });

    document.body.style.overflow = 'hidden';

    function closeHandler() {
        document.body.style.overflow = 'auto';
        close();
    }

    return (
        <div class="modal">
            <div class="modal-content">
                <div class="modal-header" style={{
                    boxShadow: '0px 22px 20px -20px hsla(0, 0%, 0%, 0.21)',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <h1>{title}</h1>
                    <button class="slim" onClick={() => closeHandler()}>
                        <FaTimes style={{fontSize: '2em'}}/>
                    </button>
                </div>
                <div class="modal-body scrolly">
                    {body}
                </div>
                <div class="modal-footer">
                </div>
            </div>
        </div>
    );
}
