import { useEffect, useMemo, useState } from 'react';

import init, {
    build_plan_graph,
} from 'wasm';

import { FeedbackBox } from './feedback-box.js'
import { PlanEditor } from './plan-editor.js';
import { PlanGraph } from './plan-graph.js';
import { Simulator } from './simulator.js';
import { monthyearToEpoch, perScaled, resolveDate } from './util.js';

export function Core({page, signedIn, plan, storeParams}) {
    // not in `useState` because it never changes
    const start = Object.values(plan.events).find(e => e.name === 'start').monthyear;

    const [birth, setBirth]
        = useState(Object.values(plan.events).find(e => e.name === 'birth').monthyear);
    const [retire, setRetire]
        = useState(Object.values(plan.events).find(e => e.name === 'retire').monthyear);
    const [end, setEnd]
        = useState(Object.values(plan.events).find(e => e.name === 'end').monthyear);
    const [filingStatus, setFilingStatus] = useState(plan.filing_status);
    const [income, setIncome] = useState(plan.income_streams);
    const [expense, setExpense] = useState(plan.expense_streams);

    const [currentAccounts, setCurrentAccounts] = useState(plan.accounts);

    const [debt, setDebt] = useState(plan.debt_schedules.map(
        debtSchedule => {
            return {
                value: {
                    schedule: {
                        balance: debtSchedule.value.schedule.balance,
                        payment: debtSchedule.value.schedule.payment,
                        // round to 3 decimal places (and 100x for user-facing format)
                        rate: (Math.round(100000 * debtSchedule.value.schedule.rate) / 1000)
                            .toString(),
                    },
                    start: debtSchedule.value.start,
                },
                note: debtSchedule.note
            };
        }
    ));

    const [events, setEvents] = useState(plan.events);

    const [wasmInitialized, setWasmInitialized] = useState(false);

    const [coverUnaccountedForCashflow, setCoverUnaccountedForCashflow] = useState(false);
    const [unaccountedForCashflowDestination, setUnaccountedForCashflowDestination]
        = useState(plan.unaccountedForCashflowDestination ?? 'cash');
    const [deflatePlanGraph, setDeflatePlanGraph] = useState(true);

    const [assets, setAssets] = useState(plan.assets ?? []);

    let planGraphData = null;
    let simulationWarning = false;

    if (wasmInitialized) {
        let plan = build_plan(
            events,
            birth,
            start,
            retire,
            end,
            filingStatus,
            income,
            expense,
            currentAccounts,
            debt,
            coverUnaccountedForCashflow ? unaccountedForCashflowDestination : null,
        );

        let plan_graph_output = build_plan_graph(JSON.stringify(plan), deflatePlanGraph);

        let { data } = JSON.parse(plan_graph_output);

        planGraphData = data;

        simulationWarning
            = data.map(x => x[1]).some(v => v < 0) || data.map(x => x[8]).some(v => v < 0);
    }

    // TODO refactor this further (or maybe just name this better)
    let runParams = {
        starting_state: build_starting_state(
            currentAccounts,
            unaccountedForCashflowDestination,
        ),
        plan: build_plan(
            events,
            birth,
            start,
            retire,
            end,
            filingStatus,
            income,
            expense,
            currentAccounts,
            debt,
            unaccountedForCashflowDestination,
        ),
    };

    // TODO refactor this further (or maybe just name this better)
    // note: the purpose of this object is to store the params in the database. there is likely a
    //       better way to accomplish this
    let currentParams = {
        birth,
        start,
        retire,
        end,
        filing_status: filingStatus,
        income_streams: income.filter(stream => !stream.deleted),
        expense_streams: expense.filter(stream => !stream.deleted),
        accounts: currentAccounts
            .filter(account => !account.deleted)
            .map(account => {
                return {
                    ...account,
                    value: {
                        ...account.value,
                        streams: account.value.streams.filter(stream => !stream.deleted)
                    }
                };
            }),
        debt_schedules: debt.filter(debtSchedule => !debtSchedule.deleted).map(
            debtSchedule => {
                return {
                    value: {
                        schedule: {
                            balance: debtSchedule.value.schedule.balance,
                            payment: debtSchedule.value.schedule.payment,
                            rate: debtSchedule.value.schedule.rate / 100,
                        },
                        start: debtSchedule.value.start,
                    },
                    note: debtSchedule.note,
                };
            }
        ),
        events,
        unaccountedForCashflowDestination,
        assets: assets.filter(asset => !asset.deleted),
    };

    function updateBirth(birth) {
        if (wasmInitialized) {
            let birth_id = Object.entries(events)
                .find(([key, value]) => value.name === 'birth')[0];

            // compute end (90th birthday) and update
            let end = {
                ...birth,
                year: birth.year + 90,
            };

            let end_id = Object.entries(events)
                .find(([key, value]) => value.name === 'end')[0];

            setEvents({
                ...events,
                [birth_id]: {
                    ...events[birth_id],
                    monthyear: birth,
                },
                [end_id]: {
                    ...events[end_id],
                    monthyear: end,
                },
            });

            setBirth(birth);
            setEnd(end);

            // adjust retire to keep it within bounds
            let maxRetire = {
                ...birth,
                year: birth.year + 72,
            };

            if (monthyearToEpoch(maxRetire) < monthyearToEpoch(retire)) {
                updateRetire(maxRetire);
            }
        }
    }

    function updateRetire(retire) {
        if (wasmInitialized) {
            let retire_id = Object.entries(events)
                .find(([key, value]) => value.name === 'retire')[0];

            setEvents({
                ...events,
                [retire_id]: {
                    ...events[retire_id],
                    monthyear: retire,
                }
            });

            setRetire(retire);
        }
    }

    useEffect(() => {
        init().then(() => setWasmInitialized(true));
    }, []);

    const baselineExpense = useMemo(() => {
        if (wasmInitialized) {
            let plan = build_plan(
                events,
                birth,
                start,
                retire,
                end,
                filingStatus,
                income,
                expense,
                currentAccounts,
                debt,
                null, // do nothing with unaccounted-for cashflow
            );

            let plan_graph_output = build_plan_graph(JSON.stringify(plan), deflatePlanGraph);

            let { data } = JSON.parse(plan_graph_output);

            // remove $0.12 for the year to account for off-by-a-penny rounding
            return Math.trunc(1200 * data[0][1] - 12) / 100;
        }

        // This should never be hit because the caller isn't even rendered until `wasmInitialized`
        // is `true`.
        return null; // TODO handle this appropriately
    }, [
        events,
        birth,
        start,
        retire,
        end,
        filingStatus,
        income,
        expense,
        currentAccounts,
        debt,
        deflatePlanGraph,
        wasmInitialized,
    ]);

    if (!wasmInitialized) return <>loading...</>

    return(
        <div id="app-body">
            {page === 'plan' && <div id="plan">
                <PlanGraph
                    data={planGraphData}
                    coverUnaccountedForCashflow={coverUnaccountedForCashflow}
                    setCoverUnaccountedForCashflow={setCoverUnaccountedForCashflow}
                    deflatePlanGraph={deflatePlanGraph}
                    setDeflatePlanGraph={setDeflatePlanGraph}
                    simulationWarning={simulationWarning}
                />
                <PlanEditor
                    events={events}
                    birth={birth}
                    setBirth={updateBirth}
                    start={start}
                    retire={retire}
                    setRetire={updateRetire}
                    end={end}
                    filingStatus={filingStatus}
                    setFilingStatus={setFilingStatus}
                    income={income}
                    setIncome={setIncome}
                    expense={expense}
                    setExpense={setExpense}
                    currentAccounts={currentAccounts}
                    setCurrentAccounts={setCurrentAccounts}
                    debt={debt}
                    setDebt={setDebt}
                    planGraphData={planGraphData}
                    baselineExpense={baselineExpense}
                    unaccountedForCashflowDestination={unaccountedForCashflowDestination}
                    setUnaccountedForCashflowDestination={setUnaccountedForCashflowDestination}
                    assets={assets}
                    setAssets={setAssets}
                />
                <FeedbackBox/>
            </div>}
            {page === 'simulate' && <Simulator
                runParams={runParams}
                currentParams={currentParams}
                simulationWarning={simulationWarning}
                storeParams={storeParams}
            />}
        </div>
    );
}

function build_starting_state(currentAccounts, unaccountedForCashflowDestination) {
    let extraPrivateAccounts = [];
    if (unaccountedForCashflowDestination === 'private') {
        extraPrivateAccounts.push({
            current_balance: 0,
            stock_ratio: 1,
        });
    }

    return {
        cash_balances: currentAccounts
            .filter(account => !account.deleted)
            .filter(account => account.value.type === 'cash')
            .map(account => account.value.current_balance),
        sponsored_401k_accounts: currentAccounts
            .filter(account => !account.deleted)
            .filter(account => account.value.type === 'sponsored_401k')
            .map(account => {
                return { current_balance: account.value.current_balance, stock_ratio: 1 }
            }
        ),
        dec_total_sponsored_401k_balance: 0,
        private_accounts: currentAccounts
            .filter(account => !account.deleted)
            .filter(account => account.value.type === 'private')
            .map(account => {
                return { current_balance: account.value.current_balance, stock_ratio: 1 }
            }
        ).concat(extraPrivateAccounts),
        roth_ira_accounts: currentAccounts
            .filter(account => !account.deleted)
            .filter(account => account.value.type === 'roth_ira')
            .map(account => {
                return { current_balance: account.value.current_balance, stock_ratio: 1 }
            }
        ),
        ytd_taxable_income: 0, // TODO
        ytd_sponsored_401k_draw: 0,
        ytd_capital_gain: 0,
        ytd_tax_paid: 0, // TODO
        tax_return: 0,
        bankrupt: false
    };
}

function build_plan(
    events,
    birth,
    start,
    retire,
    end,
    filingStatus,
    income,
    expense,
    currentAccounts,
    debt,
    unaccountedForCashflowDestination,
) {
    return {
        birth,
        start,
        retire,
        end,
        marriages: filingStatus === 'married'
            ? [ { start, end } ]
            : [],
        income_streams: income.filter(stream => !stream.deleted).map(stream => {
            return {
                value: perScaled(
                    stream.value.value,
                    stream.value.per
                ),
                start: resolveDate(events, stream.value.start),
                end: resolveDate(events, stream.value.end),
            };
        }),
        expense_streams: expense.filter(stream => !stream.deleted).map(stream => {
            return {
                value: perScaled(
                    stream.value.value,
                    stream.value.per
                ),
                start: resolveDate(events, stream.value.start),
                end: resolveDate(events, stream.value.end),
            };
        }),
        cash_accounts: currentAccounts
            .filter(account => !account.deleted)
            .filter(account => account.value.type === 'cash')
            .map(account => [
                account.value.current_balance,
                account.value.streams.filter(stream => !stream.deleted)
                    .map(stream => {
                        return {
                            value: perScaled(
                                stream.value.value,
                                stream.value.per
                            ),
                            start: resolveDate(events, stream.value.start),
                            end: resolveDate(events, stream.value.end),
                        };
                    })
                ]
            ),
        sponsored_401k_accounts: currentAccounts
            .filter(account => !account.deleted)
            .filter(account => account.value.type === 'sponsored_401k')
            .map(account => account.value.streams
                .filter(stream => !stream.deleted)
                .map(stream => {
                    return {
                        value: perScaled(
                            stream.value.value,
                            stream.value.per
                        ),
                        start: resolveDate(events, stream.value.start),
                        end: resolveDate(events, stream.value.end),
                    };
                })
            ),
        roth_ira_accounts: currentAccounts
            .filter(account => !account.deleted)
            .filter(account => account.value.type === 'roth_ira')
            .map(account => account.value.streams
                .filter(stream => !stream.deleted)
                .map(stream => {
                    return {
                        value: perScaled(
                            stream.value.value,
                            stream.value.per
                        ),
                        start: resolveDate(events, stream.value.start),
                        end: resolveDate(events, stream.value.end),
                    };
                })
            ),
        private_accounts: currentAccounts
            .filter(account => !account.deleted)
            .filter(account => account.value.type === 'private')
            .map(account => account.value.streams
                .filter(stream => !stream.deleted)
                .map(stream => {
                    return {
                        value: perScaled(
                            stream.value.value,
                            stream.value.per
                        ),
                        start: resolveDate(events, stream.value.start),
                        end: resolveDate(events, stream.value.end),
                    };
                })
            ),
        debt_schedules: debt.filter(debtSchedule => !debtSchedule.deleted).map(
            debtSchedule => {
                return {
                    start: resolveDate(events, debtSchedule.value.start),
                    schedule: {
                        balance: debtSchedule.value.schedule.balance,
                        payment: debtSchedule.value.schedule.payment,
                        rate: debtSchedule.value.schedule.rate / 100,
                    }
                };
            }
        ),
        config: {
            unaccounted_for_cashflow_destination: unaccountedForCashflowDestination,
        },
    };
}
