import { FaLongArrowAltRight } from "react-icons/fa";

import React, { useState } from 'react';

import domainName from './domain-name.js';

export function FeedbackBox() {
    const [suggestion, setSuggestion] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('text', suggestion);

        fetch(domainName + '/suggestions', {
            method: 'post',
            body: new URLSearchParams({
                text: suggestion
            })
        });

        setSuggestion('');
    };

    return (
        <form id='feedback-box' onSubmit={handleSubmit}>
            <input
                placeholder='syrplus would be even better if...'
                value={suggestion}
                onChange={e => setSuggestion(e.target.value)}
            />
            <button><FaLongArrowAltRight size={'16px'} style={{marginBottom: '-3px'}}/></button>
        </form>
    );
}
