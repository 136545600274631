import { Assets } from './assets.js';
import { MonthYearSelector } from './monthyear-selector.js';
import { monthyearFromEpoch, monthyearToEpoch } from './util.js';

export function AboutYou({
    netWorth,
    birth,
    setBirth,
    retire,
    setRetire,
    start,
    filingStatus,
    setFilingStatus,
    unaccountedForCashflowDestination,
    setUnaccountedForCashflowDestination,
    assets,
    setAssets,
}) {
    // most recent possible birthmonth
    const maxBirth = {
        ...start,
        year: start.year - 18,
    };

    // most distant possible birthmonth (probably nobody over 70 is using this)
    const minBirth = {
        ...start,
        year: start.year - 70,
    };

    const maxRetire = {
        ...birth,
        year: birth.year + 72,
    }

    const minRetire = monthyearFromEpoch(monthyearToEpoch(start) + 1);

    return (
        <div id="about-you-editor">
            <div id="net-worth">
                Current Net Worth <span>{netWorth}</span>
            </div>
            <div class="row editor-label">
                <div class="date">birth month</div>
                <div class="date">retirement</div>
                <div class="filing-status">filing&nbsp;status</div>
                <div class="cashflow-destination">extra&nbsp;cashflow</div>
            </div>
            <div class="row">
                <div class="date">
                    <MonthYearSelector
                        monthyear={birth}
                        setMonthyear={(monthyear) => setBirth(monthyear)}
                        min={minBirth}
                        max={maxBirth}
                        row={true}
                    />
                </div>
                <div class="date">
                    <MonthYearSelector
                        monthyear={retire}
                        setMonthyear={(monthyear) => setRetire(monthyear)}
                        min={minRetire}
                        max={maxRetire}
                        row={true}
                    />
                </div>
                <div class="filing-status">
                    <select
                        name="filing-status"
                        value={filingStatus}
                        onChange={e => setFilingStatus(e.target.value)}
                    >
                        <option value="single">Single</option>
                        <option value="married">Married</option>
                    </select>
                </div>
                <div class="cashflow-destination">
                    <select
                        onChange={
                            (e) => setUnaccountedForCashflowDestination(e.target.value)
                        }
                        value={unaccountedForCashflowDestination}
                    >
                        <option value='cash'>cash</option>
                        <option value='private'>brokerage</option>
                    </select>
                </div>
            </div>
            <Assets assets={assets} setAssets={setAssets}/>
        </div>
    );
}
