import { FaTrash } from "react-icons/fa";

import { EventSelector } from './event-selector.js';
import { NumericalInput } from './numerical-input.js';
import { resolveDate } from './util.js';

export function Stream({
    events,
    amount,
    start,
    end,
    note,
    planStart,
    planEnd,
    setStream,
    deleteStream
}) {
    const dateOfStart = resolveDate(events, start);

    const setSalary = (amount) => setStream(amount, start, end, note);
    const setStart = (start) => setStream(amount, start, end, note);
    const setEnd = (end) => setStream(amount, start, end, note);
    const setNote = (note) => setStream(amount, start, end, note);

    return (
        <div class="row">
            <div class="amount">
                <NumericalInput
                    value={amount.toString()}
                    setValue={setSalary}
                />
            </div>
            <div class="event">
                <EventSelector
                    events={events}
                    evt={start}
                    setEvt={setStart}
                    min={planStart}
                    max={planEnd}
                />
            </div>
            <div class="event">
                <EventSelector
                    events={events}
                    evt={end}
                    setEvt={setEnd}
                    min={dateOfStart}
                    max={planEnd}
                />
            </div>
            <div class="note">
                <input
                    value={note}
                    onChange={e => setNote(e.target.value)}
                />
            </div>
            <div class="editor-action">
                <button
                    type="button"
                    class="slim"
                    onClick={() => deleteStream()}
                >
                    <FaTrash/>
                </button>
            </div>
        </div>
    );
}
