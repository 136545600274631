import { FaPlus, FaTrash } from "react-icons/fa";

import { EventSelector } from './event-selector.js';
import { Stream } from './streams.js';
import { Tooltip } from './tooltip.js';
import { resolveDate } from './util.js';

export function Expense({
    events,
    expense,
    setExpense,
    planStart,
    planEnd,
    planGraphData,
    baselineExpense,
}) {
    function addEmptyExpenseStream() {
        setExpense([...expense, {
            value: {
                value: 0,
                per: 'year',
                start: {
                    'Event': Object.entries(events).find(([id, evt]) => evt.name === 'start')[0],
                },
                end: {
                    'Event': Object.entries(events).find(([id, evt]) => evt.name === 'end')[0],
                },
            },
            note: '',
            deleted: false,
        }]);
    }

    const setExpenseStream = (ind) => (amount, start, end, note) => {
        expense[ind] = {
            value: {
                value: amount,
                per: 'year',
                start,
                end,
            },
            note: note,
            deleted: false,
        };

        // save updated array
        setExpense([...expense]);
    }

    const deleteExpenseStream = (ind) => () => {
        expense[ind] = { ...expense[ind], deleted: true };

        // save updated array
        setExpense([...expense]);
    }

    const setBaselineExpense = () => {
        setExpense([{
            value: {
                value: baselineExpense,
                per: 'year',
                start: {
                    'Event': Object.entries(events).find(([id, evt]) => evt.name === 'start')[0],
                },
                end: {
                    'Event': Object.entries(events).find(([id, evt]) => evt.name === 'end')[0],
                },
            },
            note: '[computed] expense baseline',
            deleted: false,
        }, ...expense]);
    }

    return (
        <div id="expense-editor">
            <div id="expense-baseline">
                <button
                    class="editor-button"
                    onClick={setBaselineExpense}
                >
                    set expense baseline
                </button>
                <Tooltip>
                    <ExpenseBaselineTooltipBody/>
                </Tooltip>
            </div>
            {expense.some(stream => !stream.deleted) && <div class="row editor-label">
                <div class="amount">expense</div>
                <div class="event">start</div>
                <div class="event">end</div>
                <div class="note">name/notes (optional)</div>
                <div class="editor-action"></div>
            </div>}
            {expense.map((stream, ind) =>
                !stream.deleted && <Stream
                    key={ind}
                    events={events}
                    amount={stream.value.value}
                    start={stream.value.start}
                    end={stream.value.end}
                    note={stream.note}
                    planStart={planStart}
                    planEnd={planEnd}
                    setStream={setExpenseStream(ind)}
                    deleteStream={deleteExpenseStream(ind)}
                />
            )}
            <button
                type="button"
                class="slim"
                onClick={() => addEmptyExpenseStream()}
            >
                Add an expense stream&nbsp;&nbsp;<FaPlus
                    style={{marginBottom: '-2px'}}
                />
            </button>
        </div>
    );
}

function ExpenseBaselineTooltipBody() {
    return (
        <div id='expense-baseline-tooltip-body'>
            <p>
                Creates an expense baseline equal to the extra cash flow in the <em>first</em> month
                of your plan.
            </p>
            <p>
                To use this feature, first account for all <em>other</em> cash flow in the first
                month of your plan including income, debt payments, and all account contributions,
                and then click this button.
            </p>
        </div>
    );
}
