import 'dygraphs/dist/dygraph.min.css';
import Dygraph from 'dygraphs';
import React, { useEffect, useState } from 'react';

import { FaExclamationTriangle } from "react-icons/fa";

import { wait } from './util.js';

import {
    get_net_worth_fanchart,
    get_debt_balance_fanchart,
    get_account_balances_fanchart,
    run_calculator,
} from 'wasm';

export function Simulator({
    runParams,
    currentParams,
    simulationWarning,
    storeParams,
}) {
    return (
        <div id="simulator">
            <div id="graph-container" class="raised">
                {simulationWarning
                  ? <div class='simulation-warning'>
                        <FaExclamationTriangle class="icon"/> Please check for negative expense or
                        unaccounted-for cashflow values</div>
                  : <Graph
                        simulationWarning={simulationWarning}
                        runParams={runParams}
                        currentParams={currentParams}
                        storeParams={storeParams}
                    />}
            </div>
        </div>
    );
}

function Graph({
    simulationWarning,
    runParams,
    currentParams,
    storeParams,
}) {
    const [firstRun, setFirstRun] = useState(true);
    const [prevRunParams, setPrevRunParams] = useState(runParams);
    const colorNetWorth = 'rgb(10, 240, 236)';
    const colorCashBalance = 'rgb(0, 255, 0)';
    const colorSponsored401kBalance = 'rgb(64, 73, 230)';
    const colorRothIraBalance = 'rgb(194, 66, 245)';
    const colorPrivateBalance = 'rgb(255, 255, 0)';
    const colorDebtBalance = 'rgb(127, 39, 171)';

    const [showSponsored401kBalance, setShowSponsored401kBalance] = useState(true);
    const [showRothIraBalance, setShowRothIraBalance] = useState(true);
    const [showPrivateBalance, setShowPrivateBalance] = useState(true);
    const [showCashBalance, setShowCashBalance] = useState(true);

    const [graphLoading, setGraphLoading] = useState(false);
    const [simulationOutputQuery, setSimulationOutputQuery] = useState('net-worth');
    const [legendSettings, setLegendSettings] = useState({
        'Net Worth': {
            color: colorNetWorth
        },
    });

    function handleSimulationUpdate(
        queryCallback,
        colors
    ) {
        if (firstRun || runParams != prevRunParams) {
            run_calculator(JSON.stringify(runParams));
            setPrevRunParams(runParams);
        }
        setFirstRun(false);

        var fanchart_data_json = queryCallback();

        var {
            no_of_runs,
            no_of_bombs,
            index,
            data
        } = JSON.parse(fanchart_data_json);

        drawGraph(
            "graph",
            currentParams,
            no_of_runs,
            no_of_bombs,
            index,
            data,
            colors
        );

        storeParams(currentParams);
    }

    useEffect(() => {
        setGraphLoading(true);

        // default to net-worth
        setLegendSettings({
            'Net Worth': {
                color: colorNetWorth,
                toggle: () => {},
                shown: true,
            },
        });
        let colors = [colorNetWorth, colorNetWorth];
        let queryCallback = get_net_worth_fanchart;

        if (simulationOutputQuery === 'account-balances') {
            setLegendSettings({
                '401(k)': {
                    color: colorSponsored401kBalance,
                    toggle: () => handleFanchartToggle(
                        showSponsored401kBalance,
                        setShowSponsored401kBalance
                    ),
                    shown: showSponsored401kBalance,
                },
                'Roth IRA': {
                    color: colorRothIraBalance,
                    toggle: () => handleFanchartToggle(
                        showRothIraBalance,
                        setShowRothIraBalance
                    ),
                    shown: showRothIraBalance,
                },
                'Private Brokerage': {
                    color: colorPrivateBalance,
                    toggle: () => handleFanchartToggle(
                        showPrivateBalance,
                        setShowPrivateBalance
                    ),
                    shown: showPrivateBalance,
                },
                'Cash Savings': {
                    color: colorCashBalance,
                    toggle: () => handleFanchartToggle(
                        showCashBalance,
                        setShowCashBalance
                    ),
                    shown: showCashBalance,
                },
            });

            colors = [];
            if (showCashBalance) colors.push(colorCashBalance, colorCashBalance);
            if (showSponsored401kBalance)
                colors.push(colorSponsored401kBalance, colorSponsored401kBalance);
            if (showRothIraBalance) colors.push(colorRothIraBalance, colorRothIraBalance);
            if (showPrivateBalance) colors.push(colorPrivateBalance, colorPrivateBalance);

            queryCallback = () => get_account_balances_fanchart(
                JSON.stringify({
                    include_cash_balance: showCashBalance,
                    include_sponsored_401k_balance: showSponsored401kBalance,
                    include_roth_ira_balance: showRothIraBalance,
                    include_private_balance: showPrivateBalance,
                })
            );
        } else if (simulationOutputQuery === 'debt-balance') {
            setLegendSettings({
                'Debt Balance': {
                    color: colorDebtBalance,
                    toggle: () => {},
                    shown: true,
                },
            });

            colors = [colorDebtBalance, colorDebtBalance];

            queryCallback = get_debt_balance_fanchart;
        }

        wait()
            .then(() => handleSimulationUpdate(queryCallback, colors))
            .then(() => setGraphLoading(false));

    }, [
        simulationOutputQuery,
        showSponsored401kBalance,
        showRothIraBalance,
        showPrivateBalance,
        showCashBalance,
    ]);

    const handleFanchartToggle = (state, setter) => {
        setter(!state);
    }

    const handleQueryChange = (e) => {
        setSimulationOutputQuery(e.target.value);
    }

    return (
        <>
            <div id="graph-container-header">
                <select id='fanchart-select' onChange={e => handleQueryChange(e)}>
                    <option value='net-worth'>Net Worth</option>
                    <option value='account-balances'>Account Balances</option>
                    <option value='debt-balance'>Debt Balance</option>
                </select><FanchartLedgend settings={legendSettings}/>
            </div>
            {graphLoading
              ? <div id="graph-loading">Loading...</div>
              : null}
            <div id="graph"></div>
        </>
    );
}

function FanchartLedgend({settings}) {
    const nShownLegendItems = Object.entries(settings)
        .filter(([name, {color, toggle, shown}]) => shown)
        .length;

    return (
        <ul id='graph-legend'>
            {Object.entries(settings).map(([name, {color, toggle, shown}]) => {
                let semiColor = shown ? rgb2rgba(color, 0.2) : 'rgba(255, 255, 255, 0.2)';
                color = shown ? color : 'rgba(255, 255, 255, 0)';

                return <li key={name} onClick={toggle} style={{background: semiColor}}>
                    <div>
                        <span style={{color: color}}>ー</span>&nbsp;&nbsp;{`${name}`}
                    </div>
                    {(!shown || nShownLegendItems > 1)
                        ? <input type='checkbox' checked={shown} readOnly/>
                        : null}
                </li>
            })}
        </ul>
    );
}

function rgb2rgba(color, alpha) {
    return 'rgba' + color.slice(3).slice(0, -1) + ', ' + alpha + ')'
}

function drawGraph(
    graphDivId,
    current_params,
    no_of_runs,
    no_of_bombs,
    index,
    data,
    colors,
) {
    var s = no_of_runs - no_of_bombs; // number of successes
    var r = no_of_runs; // number of runs

    var success_rate = (100*s/r).toFixed(0);

    var plot_data = data.map((e, i) => {e.unshift(index[i]); return e});

    // make graph
    new Dygraph(
        document.getElementById(graphDivId),
        plot_data,
        {
            colors: colors,
            errorBars: true,
            customBars: true,
            title: `${r} trials, ${success_rate}% success rate`,
            axisLabelColor: 'white',
            axisLineColor: 'white',
            showLabelsOnHighlight: false,
            labelsKMB: true,
            xlabel: 'age',
        }
    );
}

