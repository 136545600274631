import './App.css';

import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { DemoBanner } from './demo-banner.js';
import domainName from './domain-name.js';
import { Help } from './help.js';
import { Modal } from './modal.js';
import { Navbar } from './navbar.js';
import { PlanManager } from './plan-manager.js';

function App() {
    const [signedIn, setSignedIn] = useState(false);
    const [checkedSignedIn, setCheckedSignedIn] = useState(false);
    const [helpModalOpen, setHelpModalOpen] = useState(false);
    const [page, setPage] = useState('plan');

    useEffect(() => {
        fetch(domainName + '/signin/validate', {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
        })
        .then(response => {
            if (response.ok) {
                setSignedIn(true);
            }
            setCheckedSignedIn(true);
        })
        .catch(() => setCheckedSignedIn(true));
    }, []);

    return (
        <Router>
            {checkedSignedIn && <>
                {helpModalOpen
                  ? <Modal title={'Help'} close={() => setHelpModalOpen(false)} body={<Help/>}/>
                  : null
                }
                {!signedIn
                  ? <DemoBanner/>
                  : <></>}
                <Navbar
                    page={page}
                    setPagePlan={() => setPage('plan')}
                    setPageSimulate={() => setPage('simulate')}
                    signedIn={signedIn}
                    setSignedIn={setSignedIn}
                    openHelpModal={() => setHelpModalOpen(true)}
                />
                <Routes>
                    <Route path="/:planId?"
                        element={
                        <PlanManager page={page} signedIn={signedIn}>
                        </PlanManager>
                        }
                    >
                    </Route>
                </Routes>
            </>}
        </Router>
    );
}

export default App;
