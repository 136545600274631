import { FaPlus, FaTrash } from "react-icons/fa";

import { PerSelector } from './per-selector.js';
import { EventSelector } from './event-selector.js';
import { NumericalInput } from './numerical-input.js';
import { resolveDate } from './util.js';

export function ContributionStreams({events, streams, setStreams, planStart, retire}) {
    function addEmptyStream() {
        setStreams([...streams, {
            value: {
                value: 0,
                per: 'month',
                start: {
                    'Event': Object.entries(events).find(([id, evt]) => evt.name === 'start')[0],
                },
                end: {
                    'Event': Object.entries(events).find(([id, evt]) => evt.name === 'retire')[0],
                },
            },
            note: '',
            deleted: false,
        }]);
    }

    const setStream = (ind) => (value, per, start, end) => {
        streams[ind] = {
            value: {
                value,
                per,
                start,
                end,
            },
            note: '',
            deleted: false,
        };
        // save updated array
        setStreams([...streams]);
    }

    const deleteStream = (ind) => () => {
        streams[ind] = { ...streams[ind], deleted: true };

        // save updated array
        setStreams([...streams]);
    }

    return (
        <div class="stream-list">
            {streams.some(stream => !stream.deleted) && <div class="row editor-label">
                <div class="contribution">contrib</div>
                <div class="per">per</div>
                <div class="event">start</div>
                <div class="event">end</div>
                <div class="editor-action"></div>
            </div>}
            {streams.map((stream, ind) =>
                !stream.deleted && <ContributionStream
                    key={ind}
                    events={events}
                    value={stream.value.value}
                    per={stream.value.per}
                    start={stream.value.start}
                    end={stream.value.end}
                    planStart={planStart}
                    retire={retire}
                    setStream={setStream(ind)}
                    deleteStream={deleteStream(ind)}
                />
            )}
            <button
                type="button"
                class="slim"
                onClick={addEmptyStream}
            >
                Add contributions&nbsp;&nbsp;<FaPlus
                    style={{marginBottom: '-2px'}}
                />
            </button>
        </div>
    );
}

function ContributionStream({
    events,
    value,
    per,
    start,
    end,
    planStart,
    retire,
    setStream,
    deleteStream,
}) {
    const dateOfStart = resolveDate(events, start);
    const setValue = (value) => setStream(value, per, start, end)
    const setPer = (per) => setStream(value, per, start, end)
    const setStart = (start) => setStream(value, per, start, end)
    const setEnd = (end) => setStream(value, per, start, end)

    return (
        <div class="stream">
            <div class="row">
                <div class="contribution">
                    <NumericalInput
                        value={value}
                        setValue={setValue}
                    />
                </div>
                <div class="per">
                    <PerSelector
                        per={per}
                        setPer={setPer}
                    />
                </div>
                <div class="event">
                    <EventSelector
                        events={events}
                        evt={start}
                        setEvt={setStart}
                        min={planStart}
                        max={retire}
                    />
                </div>
                <div class="event">
                    <EventSelector
                        events={events}
                        evt={end}
                        setEvt={setEnd}
                        min={dateOfStart}
                        max={retire}
                    />
                </div>
                <div class="editor-action">
                    <button
                        type="button"
                        class="slim"
                        onClick={() => deleteStream()}
                    >
                        <FaTrash/>
                    </button>
                </div>
            </div>
        </div>
    );
}
