export function Help() {
    // TODO: Maybe at some point make these sections collapsable
    return (
        <>
        <h3>What do I do?</h3>
        <ul style={{margin: "-5px 20px 40px 20px"}}>
            <li>Use the Plan Editor on the "PLAN" tab to enter info about your financial plan</li>
            <li>Click the "SIMULATE" tab and your simulation will run automatically</li>
            <li>Look at cool and awesome graph</li>
            <li>If graph is not cool and awesome, make tweaks and repeat</li>
        </ul>
        <h3>So what is going on here?</h3>
        <p style={{margin: "20px"}}>
            Syrplus runs a month-by-month simulation of your retirement strategy which during your
            career includes:
        </p>
        <ul style={{margin: "-5px 20px 20px 20px"}}>
            <li>earning income</li>
            <li>making pre-tax investment contributions</li>
            <li>paying income taxes</li>
            <li>paying down debt</li>
            <li>making post-tax invesment contributions</li>
            <li>paying all other expenses</li>
        </ul>
        <p style={{margin: "20px"}}>
            and during your retirement includes:
        </p>
        <ul style={{margin: "-5px 20px 20px 20px"}}>
            <li>drawing funds from all available retirement resources</li>
            <li>paying income and capital gains taxes</li>
            <li>paying down debt</li>
            <li>paying all other expenses</li>
            <li>ensuring Required Minimum Distributions are met (and re-investing excess into
                a private brokerage account</li>
        </ul>
        <p style={{margin: "20px 20px 40px 20px"}}>
            Once the simulation has run, a fanchart of net worth is shown. The net worth shown
            includes cash balance, all investment account balances, minus all outstanding debt.
            Assets are not included. Fancharts for account balances according to tax status are
            available via the selector on the top left as well as a line chart for debt balance.
            The portfolio success rate is shown at the top of the graph.
        </p>
        <h3>What is a fanchart?</h3>
        <p style={{margin: "20px"}}>
            A fanchart is a type of graph used to show a projection that contains uncertainty.
            It&#39;s not possible to exactly predict the price of assets in your retirement
            portfolio, so It&#39;s not possible to say exactly what your net worth will be in the
            future. Instead we show a range of possible outcomes.
        </p>
        <ul style={{margin: "-5px 20px 40px 20px"}}>
            <li>the solid line represents the median value</li>
            <li>the inner band covers the middle 50% of cases</li>
            <li>the outer band covers the middle 90% of cases</li>
            <li>the remaining 10% of cases are not represented</li>
        </ul>
        <h3>Assumptions</h3>
        <p style={{margin: "20px"}}>
            Syrplus does make a number of assumptions that you should be aware of. In the near
            future, all of these things will be adjustable.
        </p>
        <ul style={{margin: "-5px 20px 40px 20px"}}>
            <li>income, expenses, and investment contributions all scale with inflation (taken to
            be 3.4%, increased once per year)</li>
            <li>all investments are allocated to low-cost total stock market index funds</li>
            <li>any unaccounted-for cashflow goes to cash savings</li>
            <li>you file taxes in the state of Maine (where Syrplus lives)</li>
            <li>
                funds are drawn from retirement accounts such that the accounts maintain their
                relative balances, e.g., if your portfolio contains half pre-tax and half post-tax
                before the withdrawal, it will still be half and half after the withdrawal.
            </li>
        </ul>
        <h3>Notes</h3>
        <ul style={{margin: "-5px 20px 40px 20px"}}>
            <li>
                if you are filing as married, it is probably better to use the birth date of the
                younger spouse (better married-filing support is coming soon!)
            </li>
            <li>
                capital gains cannot be computed for any existing private brokerage account balances
            </li>
        </ul>
        </>
    );
}
