import { FaSignOutAlt } from "react-icons/fa";

import domainName from './domain-name.js';

export function Signout({setSignedIn}) {
    const signoutHandler = () => {
        //e.preventDefault();
        fetch(domainName + '/signout', {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
        })
        .then(() => {
            setSignedIn(false);
            window.location.href = "https://syrplus.io";
        })
    }

    return (
        <button onClick={(e) => signoutHandler(e)}><FaSignOutAlt
            class="button-icon"
        />&nbsp;&nbsp;Sign out</button>
    );
}
