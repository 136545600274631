import { FaPlus, FaTrash } from "react-icons/fa";
import { NumericalInput } from './numerical-input.js';

export function Assets({assets, setAssets}) {
    function addAsset() {
        console.log('hello');
        setAssets([...assets, {
            value: 0,
            note: '',
            deleted: false,
        }]);
    }

    const setAsset = (ind) => (value, note) => {
        assets[ind] = {
            value: value,
            note: note,
            deleted: false,
        };

        // save updated array
        setAssets([...assets]);
    }

    const deleteAsset = (ind) => () => {
        assets[ind] = { ...assets[ind], deleted: true };

        // save updated array
        setAssets([...assets]);
    }

    return (
        <div id="asset-editor" style={{marginTop: '30px'}}>
            {assets.some(asset => !asset.deleted) && <div class="row editor-label">
                <div class="value">value</div>
                <div class="note">name/notes (optional)</div>
                <div class="editor-action"></div>
            </div>}
            {assets.map((asset, ind) =>
                !asset.deleted && <Asset
                    key={ind}
                    value={asset.value}
                    note={asset.note}
                    setAsset={setAsset(ind)}
                    deleteAsset={deleteAsset(ind)}
                />
            )}
            <button
                type="button"
                class="slim"
                onClick={addAsset}
            >
                Add equity or an asset&nbsp;&nbsp;<FaPlus
                    style={{marginBottom: '-2px'}}
                />
            </button>
        </div>
    );
}

function Asset({
    value,
    note,
    setAsset,
    deleteAsset,
}) {
    const setValue = (value) => setAsset(value, note);
    const setNote = (note) => setAsset(value, note);

    return (
        <div class="row">
            <div class="value">
                <NumericalInput
                    value={value}
                    setValue={setValue}
                />
            </div>
            <div class="note">
                <input
                    value={note}
                    onChange={e => setNote(e.target.value)}
                />
            </div>
            <div class="editor-action">
                <button
                    type="button"
                    class="slim"
                    onClick={() => deleteAsset()}
                >
                    <FaTrash/>
                </button>
            </div>
        </div>
    );
}
