export function Tooltip({children}) {
    return (
        <div class="tooltip">
            <Icon/>
            <div class="tooltip-body">
                {children}
            </div>
        </div>
    );
}

export function Icon({color, size}) {
    // defaults
    size ??= 14;
    color ??= 'white';

    return (
        <svg
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            height={`${size}px`}
            width={`${size}px`}
            style={{
                transform: `translate(3px, -3px)`
            }}
        >
            // surrounding ring
            <ellipse
                style={{
                    stroke: color,
                    strokeWidth: '7%'
                }}
                cx={"50%"}
                cy={"50%"}
                rx={"42%"}
                ry={"42%"}
            >
            </ellipse>

            <g transform={"matrix(4.2, 0, 0, 4.2, -533, -250)"}>
                // tittle of the 'i'
                <ellipse
                    style={{
                        paintOrder: 'fill',
                        fill: color,
                    }}
                    cx={"139"}
                    cy={"65"}
                    rx={"1.5"}
                    ry={"1.5"}
                ></ellipse>

                // body of the 'i'
                <path
                    style={{
                        fill: color,
                    }}
                    d={`M 135 68 L 135 70 L 138 70 L 138 76 L 135 76 L 135 78 L 143 78 L 143 76
                        L 140 76 L 140 69.986 C 139.967 69.295 139.853 68.878 139.455 68.458
                        C 139.101 68.085 138.699 67.987 138 68 L 135 68 Z`}>
                </path>
            </g>
        </svg>
    );
}
