import { FaPlus } from "react-icons/fa";

import { Stream } from './streams.js';

export function Income({events, income, setIncome, retire, planStart, planEnd}) {
    function addEmptyIncomeStream() {
        setIncome([...income, {
            value: {
                value: 0,
                per: 'year',
                start: {
                    'Event': Object.entries(events).find(([id, evt]) => evt.name === 'start')[0],
                },
                end: {
                    'Event': Object.entries(events).find(([id, evt]) => evt.name === 'retire')[0],
                },
            },
            note: '',
            deleted: false,
        }]);
    }

    const setIncomeStream = (ind) => (salary, start, end, note) => {
        income[ind] = {
            value: {
                value: salary,
                per: 'year',
                start,
                end,
            },
            note: note,
            deleted: false,
        };

        // save updated array
        setIncome([...income]);
    }

    const deleteIncomeStream = (ind) => () => {
        income[ind] = { ...income[ind], deleted: true };

        // save updated array
        setIncome([...income]);
    }

    return (
        <div id="income-editor">
            {income.some(stream => !stream.deleted) && <div class="row editor-label">
                <div class="salary">salary</div>
                <div class="event">start</div>
                <div class="event">end</div>
                <div class="note">name/notes (optional)</div>
                <div class="editor-action"></div>
            </div>}
            {income.map((stream, ind) =>
                !stream.deleted && <Stream
                    key={ind}
                    events={events}
                    amount={stream.value.value}
                    start={stream.value.start}
                    end={stream.value.end}
                    note={stream.note}
                    planStart={planStart}
                    planEnd={planEnd}
                    setStream={setIncomeStream(ind)}
                    deleteStream={deleteIncomeStream(ind)}
                />
            )}
            <button
                type="button"
                class="slim"
                onClick={() => addEmptyIncomeStream()}
            >
                Add an income stream&nbsp;&nbsp;<FaPlus
                    style={{marginBottom: '-2px'}}
                />
            </button>
        </div>
    );
}
