import { FaExclamationTriangle } from "react-icons/fa";
import { Tooltip } from './tooltip.js';

import React, { useEffect, useState } from 'react';

import Chart from 'chart.js/auto';
import zoomPlugin from "chartjs-plugin-zoom";
Chart.register(zoomPlugin);

export function PlanGraph({
    data,
    coverUnaccountedForCashflow,
    setCoverUnaccountedForCashflow,
    deflatePlanGraph,
    setDeflatePlanGraph,
    simulationWarning,
}) {
    const [chart, setChart] = useState(null);
    const [dataLength, setDataLength] = useState(0);

    useEffect(() => {
        if (data) {
            setDataLength(data.length);

            let datasets = [
                {
                    label: 'living expenses',
                    data: data.map(x => x[8]),
                    backgroundColor: 'rgba(255, 255, 255)',
                },
                {
                    label: 'pre-tax contributions',
                    data: data.map(x => x[3]),
                    backgroundColor: 'rgb(97, 198, 237)',
                },
                {
                    label: 'post-tax contributions',
                    data: data.map(x => x[4]),
                    backgroundColor: 'rgb(194, 66, 245)',
                },
                {
                    label: 'taxable contributions',
                    data: data.map(x => x[5]),
                    backgroundColor: 'rgb(255, 255, 0)',
                },
                {
                    label: 'extra cashflow',
                    data: data.map(x => x[1]),
                    backgroundColor: 'rgb(224, 34, 34)',
                },
                {
                    label: 'cash contributions',
                    data: data.map(x => x[6]),
                    backgroundColor: 'rgb(20, 163, 53)',
                },
                {
                    label: 'tax burden',
                    data: data.map(x => x[2]),
                    backgroundColor: '#f58e20',
                },
                {
                    label: 'debt payments',
                    data: data.map(x => x[7]),
                    backgroundColor: 'rgb(80, 36, 150)',
                },
            ];

            if (chart && data.length === dataLength) {
                chart.data.datasets = datasets;
                // apparently this callback goes stale, so update it too
                chart.options.plugins.tooltip.callbacks.title = (ctx) => {
                    let s = data[ctx[0].dataIndex][0];
                    return [s.slice(0, 8), `age ${s.slice(-3, -1)}`]
                };

                chart.update();
                setChart(chart);
            } else {
                if (chart) {
                    chart.destroy();
                }

                setChart(new Chart(document.getElementById('plan-graph'), {
                    type: 'bar',
                    data: {
                        labels: data.map(x => {
                            let s = x[0];
                            return s.slice(0, 4) + `'` + s.slice(6, 8);
                        }),
                        datasets: datasets,
                    },
                    options: {
                        animation: false,
                        interaction: {
                            mode: 'nearest',
                            axis: 'x',
                            intersect: false
                        },
                        plugins: {
                            legend: false,
                            tooltip: {
                                callbacks: {
                                    title: (ctx) => {
                                        let s = data[ctx[0].dataIndex][0];
                                        return [s.slice(0, 8), `age ${s.slice(-3, -1)}`]
                                    }
                                },
                            },
                            zoom: {
                                pan: {
                                    enabled: true,
                                    mode: 'x',
                                },
                                zoom: {
                                    wheel: {
                                        enabled: true,
                                    },
                                    pinch: {
                                        enabled: true,
                                    },
                                    mode: 'x',
                                },
                            },
                        },
                        scales: {
                            y: {
                                stacked: true
                            },
                            x: {
                                stacked: true,
                            },
                        }
                    }
                }));
            }
        }

    }, [data]);

    return (
        <div id="plan-graph-container" class="raised">
            {data
              ? <>
                    <div id='plan-graph-header'>
                        career monthly cash flow
                        <Tooltip>
                            <PlanGraphTooltipMessage/>
                        </Tooltip>
                    </div>
                    <canvas id="plan-graph"></canvas>
                    <div id='plan-graph-footer'>
                        <div class='simulation-warning'>
                            {simulationWarning &&
                                <><FaExclamationTriangle class="icon"/> Please check for negative
                                expense or negative extra cashflow values</>}
                        </div>
                        <div id='plan-graph-toggles'>
                            <div
                                id='unaccounted-for-cashflow-toggle'
                                onClick={() => setCoverUnaccountedForCashflow(
                                    !coverUnaccountedForCashflow
                                )}
                            >
                                highlight extra cashflow&nbsp;&nbsp;<input
                                    type='checkbox'
                                    checked={!coverUnaccountedForCashflow}
                                    readOnly
                                />
                            </div>
                            <div
                                id='deflation-toggle'
                                onClick={() => setDeflatePlanGraph(!deflatePlanGraph)}
                            >
                                show in today's dollars&nbsp;&nbsp;<input
                                    type='checkbox'
                                    checked={deflatePlanGraph}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                </>
              : 'graph will be drawn here'
            }
        </div>
    );
}

function PlanGraphTooltipMessage() {
    return (
        <div id='plan-graph-tooltip-message'>
            <p>This bar chart shows your cash flow for every month of your career.</p>
            <p>You can mouse-over for details, scroll to zoom, and click+drag to pan.</p>
        </div>
    );
}
