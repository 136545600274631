import { monthyearToEpoch, monthyearWithinBounds } from './util.js';

export function MonthYearSelector({
    monthyear,
    setMonthyear,
    min,
    max,
    def,
    row=false,
    disabled=false,
}) {
    const yearRange = [...Array(max.year - min.year + 1).keys()]
        .map(x => x + min.year)
        .filter((year) =>
            monthyear == null || monthyearWithinBounds({ ...monthyear, year: year }, min, max)
        );

    const setValidMonthyear = (value) => {
        if (monthyearToEpoch(value) < monthyearToEpoch(min)) {
            setMonthyear({
                ...value,
                year: value.year + 1,
            });
        }

        if (monthyearToEpoch(max) < monthyearToEpoch(value)) {
            setMonthyear({
                ...value,
                year: value.year - 1,
            });
        }

        setMonthyear(value);
    }

    return (
        <div class={`monthyear-selector${row ? '-row' : ''}`}>
            <select
                name="month"
                value={monthyear?.month ?? 'nonmonth'}
                onChange={
                    e => setValidMonthyear({
                        ...(monthyear ?? def),
                        month: e.target.value,
                    })
                }
                disabled={disabled}
            >
                <option value="nonmonth" disabled hidden>month</option>
                <option value="jan">Jan</option>
                <option value="feb">Feb</option>
                <option value="mar">Mar</option>
                <option value="apr">Apr</option>
                <option value="may">May</option>
                <option value="jun">Jun</option>
                <option value="jul">Jul</option>
                <option value="aug">Aug</option>
                <option value="sep">Sep</option>
                <option value="oct">Oct</option>
                <option value="nov">Nov</option>
                <option value="dec">Dec</option>
            </select>
            <select
                name="year"
                value={monthyear?.year?.toString() ?? 'nonyear'}
                onChange={
                    e => setValidMonthyear({
                        ...(monthyear ?? def),
                        year: Number(e.target.value),
                    })
                }
                disabled={disabled}
            >
                <option value="nonyear" disabled hidden>year</option>
                { yearRange.map((year, ind) => <option value={`${year}`} key={ind}>{year}</option>) }
            </select>
        </div>
    );
}
