import { FaCaretDown, FaCaretRight, FaPlus, FaTrash } from "react-icons/fa";

import { useState } from 'react';

import { ContributionStreams } from './contribution-streams.js';
import { NumericalInput } from './numerical-input.js';

export function Accounts({
    events,
    currentAccounts,
    setCurrentAccounts,
    planStart,
    retire,
}) {
    let nPrivateAccounts = currentAccounts
        .filter(account => !account.deleted)
        .filter(account => account.value.type === 'private')
        .length;

    let nCashAccounts = currentAccounts
        .filter(account => !account.deleted)
        .filter(account => account.value.type === 'cash')
        .length;

    let nSponsored401kAccounts = currentAccounts
        .filter(account => !account.deleted)
        .filter(account => account.value.type === 'sponsored_401k')
        .length;

    function addEmptyAccount() {
        setCurrentAccounts([...currentAccounts, {
            value: {
                type: 'cash',
                current_balance: 0,
                streams: [],
            },
            note: '',
            expanded: true,
            deleted: false,
        }]);
    }

    const updateAccount = (ind) => (type, balance, streams, note, expanded) => {
        currentAccounts[ind] = {
            value: {
                type: type,
                current_balance: balance,
                streams: streams,
            },
            note: note,
            expanded,
            deleted: false,
        };

        // save updated array
        setCurrentAccounts([...currentAccounts]);
    }

    const deleteAccount = (ind) => () => {
        currentAccounts[ind] = { ...currentAccounts[ind], deleted: true };

        // save updated array
        setCurrentAccounts([...currentAccounts]);
    }

    return (
        <div id="accounts-editor">
            {currentAccounts.some(account => !account.deleted) && <div class="row editor-label">
                <div class="editor-action"></div>
                <div class="account-type">type</div>
                <div class="balance">balance</div>
                <div class="note">name/notes (optional)</div>
                <div class="editor-action"></div>
            </div>}
            {currentAccounts.map((account, ind) => {
                let enforced = account.value.type === 'cash' && nCashAccounts === 1
                    || (account.value.type === 'private' && nPrivateAccounts === 1
                        && nSponsored401kAccounts > 0);
                return !account.deleted && <Account
                    key={ind}
                    events={events}
                    type={account.value.type}
                    balance={account.value.current_balance}
                    streams={account.value.streams}
                    note={account.note}
                    expanded={account.expanded}
                    planStart={planStart}
                    retire={retire}
                    enforced={enforced}
                    updateAccount={updateAccount(ind)}
                    deleteAccount={deleteAccount(ind)}
                />
            })}
            <button
                type="button"
                class="slim"
                onClick={() => addEmptyAccount()}
            >
                Add an account&nbsp;&nbsp;<FaPlus
                    style={{marginBottom: '-2px'}}
                />
            </button>
        </div>
    );
}

function Account({
    events,
    type,
    balance,
    streams,
    note,
    expanded,
    planStart,
    retire,
    enforced,
    updateAccount,
    deleteAccount,
}) {
    const setType = (type) => updateAccount(type, balance, streams, note, expanded);
    const setBalance = (balance) => updateAccount(type, balance, streams, note, expanded);
    const setStreams = (streams) => updateAccount(type, balance, streams, note, expanded);
    const setNote = (note) => updateAccount(type, balance, streams, note, expanded);
    const setExpanded = (expanded) => updateAccount(type, balance, streams, note, expanded);

    expanded = expanded ?? true; // `expanded` field was added later, so provide a default value

    return (
        <>
        <div class="account">
            <div class="row">
                <div class="editor-action">
                    <button
                        type="button"
                        class="slim"
                        onClick={() => setExpanded(!expanded)}
                    >
                        {expanded
                          ? <FaCaretDown style={{marginLeft: '-4px'}} size={20}/>
                          : <FaCaretRight style={{marginLeft: '-4px'}} size={20}/>}
                    </button>
                </div>
                <div class="account-type">
                    <AccountTypeSelector
                        type={type}
                        setType={e => setType(e.target.value)}
                    />
                </div>
                <div class="balance">
                    <NumericalInput
                        value={balance}
                        setValue={setBalance}
                    />
                </div>
                <div class="note">
                    <input
                        value={note}
                        onChange={e => setNote(e.target.value)}
                    />
                </div>
                {!enforced && <div class="editor-action">
                    <button
                        type="button"
                        class="slim"
                        onClick={() => deleteAccount()}
                    >
                        <FaTrash/>
                    </button>
                </div>}
            </div>
            {expanded && <ContributionStreams
                events={events}
                streams={streams}
                setStreams={setStreams}
                planStart={planStart}
                retire={retire}
            />}
        </div>
        </>
    );
}

function AccountTypeSelector({type, setType}) {
    return (
        <select name="account-type" value={type} onChange={e => setType(e)}>
            <option value="cash">Cash</option>
            <option value="sponsored_401k">401(k)</option>
            <option value="roth_ira">Roth IRA</option>
            <option value="private">Brokerage</option>
        </select>
    );
}
