import { NOW } from './util.js';

export const defaultParams = {
    "events": {
        "2910721e-9aba-476e-b331-ffe6c4c22a4e": {
            "name": "start",
            "monthyear": NOW
        },
        "30e41012-8770-490a-b522-b3a12ecbd6bc": {
            "name": "birth",
            "monthyear": {
                "year": 2000,
                "month": "jan"
            }
        },
        "710bcda0-0c99-4170-95dd-c11c3a28a70a": {
            "name": "end",
            "monthyear": {
                "year": 2090,
                "month": "jan"
            }
        },
        "89280dc0-9b7f-4b89-8262-99bdc539d292": {
            "name": "retire",
            "monthyear": {
                "year": 2059,
                "month": "jul"
            }
        }
    },
    "accounts": [
        {
            "note": "[example] My 401(k)",
            "value": {
                "type": "sponsored_401k",
                "streams": [
                    {
                        "note": "",
                        "value": {
                            "end": {
                                "Event": "89280dc0-9b7f-4b89-8262-99bdc539d292"
                            },
                            "per": "month",
                            "start": {
                                "Event": "2910721e-9aba-476e-b331-ffe6c4c22a4e"
                            },
                            "value": 200.0
                        }
                    }
                ],
                "current_balance": 12000.0
            }
        },
        {
            "note": "[example] Fidelity Acct",
            "value": {
                "type": "private",
                "streams": [
                    {
                        "note": "",
                        "value": {
                            "end": {
                                "Event": "89280dc0-9b7f-4b89-8262-99bdc539d292"
                            },
                            "per": "month",
                            "start": {
                                "Event": "2910721e-9aba-476e-b331-ffe6c4c22a4e"
                            },
                            "value": 100.0
                        }
                    }
                ],
                "current_balance": 0.0
            }
        },
        {
            "note": "[example] High-yield savings",
            "value": {
                "type": "cash",
                "streams": [],
                "current_balance": 1000.0
            }
        },
    ],
    "filing_status": "single",
    "debt_schedules": [
        {
            "note": "[example] mortgage",
            "value": {
                "start": {
                    "Event": "2910721e-9aba-476e-b331-ffe6c4c22a4e"
                },
                "schedule": {
                    "rate": 0.035,
                    "balance": 221000.0,
                    "payment": 1100.0
                }
            }
        },
    ],
    "income_streams": [
        {
            "note": "[example] Big Co",
            "value": {
                "end": {
                    "Event": "89280dc0-9b7f-4b89-8262-99bdc539d292"
                },
                "per": "year",
                "start": {
                    "Event": "2910721e-9aba-476e-b331-ffe6c4c22a4e"
                },
                "value": 72000.0
            }
        },
        {
            "note": "[example] senior promotion",
            "value": {
                "end": {
                    "Event": "89280dc0-9b7f-4b89-8262-99bdc539d292"
                },
                "per": "year",
                "start": {
                    "MonthYear": {
                        month: "jan",
                        year: 2029,
                    },
                },
                "value": 10000.0
            }
        }
    ],
    "expense_streams": [
        {
            "note": "[computed] expense baseline",
            "value": {
                "end": {
                    "Event": "710bcda0-0c99-4170-95dd-c11c3a28a70a"
                },
                "per": "year",
                "start": {
                    "Event": "2910721e-9aba-476e-b331-ffe6c4c22a4e"
                },
                "value": 39171.96,
            }
        }
    ],
    "assets": [
        {
            "note": "home equity",
            "value": 300000.,
        }
    ],
};
