import { FaPlus, FaTrash } from "react-icons/fa";

import { EventSelector } from './event-selector.js';
import { NumericalInput } from './numerical-input.js';

export function Debt({events, debt, setDebt, planStart, planEnd}) {
    function addEmptyDebtSchedule() {
        setDebt([...debt, {
            value: {
                schedule: {
                    balance: 0,
                    payment: 0,
                    rate: 0,
                },
                start: {
                    MonthYear: planStart,
                },
            },
            note: '',
            deleted: false,
        }]);
    }

    const setDebtSchedule = (ind) => (balance, payment, rate, start, note) => {
        debt[ind] = {
            value: {
                schedule: {
                    balance: balance,
                    payment: payment,
                    rate: rate,
                },
                start,
            },
            note,
            deleted: false,
        };

        // save updated array
        setDebt([...debt]);
    }

    const deleteDebtSchedule = (ind) => () => {
        debt[ind] = { ...debt[ind], deleted: true };

        // save updated array
        setDebt([...debt]);
    }

    return(
        <div id="debt-editor">
            {debt.some(schedule => !schedule.deleted) && <div class="row editor-label">
                <div class="balance">balance</div>
                <div class="payment">payment</div>
                <div class="rate">rate (%)</div>
                <div class="event">start</div>
                <div class="note">name/notes (optional)</div>
                <div class="editor-action"></div>
            </div>}
            {debt.map((debtSchedule, ind) =>
                !debtSchedule.deleted && <DebtSchedule
                    key={ind}
                    events={events}
                    balance={debtSchedule.value.schedule.balance}
                    payment={debtSchedule.value.schedule.payment}
                    rate={debtSchedule.value.schedule.rate}
                    start={debtSchedule.value.start}
                    planStart={planStart}
                    planEnd={planEnd}
                    note={debtSchedule.note}
                    setDebtSchedule={setDebtSchedule(ind)}
                    deleteDebtSchedule={deleteDebtSchedule(ind)}
                />
            )}
            <button
                type="button"
                class="slim"
                onClick={() => addEmptyDebtSchedule()}
            >
                Add a debt schedule&nbsp;&nbsp;<FaPlus
                    style={{marginBottom: '-2px'}}
                />
            </button>
        </div>
    );
}

function DebtSchedule({
    events,
    balance,
    payment,
    rate,
    start,
    planStart,
    planEnd,
    note,
    setDebtSchedule,
    deleteDebtSchedule
}) {
    const setBalance = (balance) => setDebtSchedule(balance, payment, rate, start, note);
    const setPayment = (payment) => setDebtSchedule(balance, payment, rate, start, note);
    const setRate = (rate) => setDebtSchedule(balance, payment, rate, start, note);
    const setStart = (start) => setDebtSchedule(balance, payment, rate, start, note);
    const setNote = (note) => setDebtSchedule(balance, payment, rate, start, note);

    return (
        <div class="row">
            <div class="balance">
                <NumericalInput
                    value={balance}
                    setValue={setBalance}
                />
            </div>
            <div class="payment">
                <NumericalInput
                    value={payment}
                    setValue={setPayment}
                />
            </div>
            <div class="rate">
                <NumericalInput
                    value={rate}
                    setValue={setRate}
                />
            </div>
            <div class="event">
                <EventSelector
                    events={events}
                    evt={start}
                    setEvt={setStart}
                    min={planStart}
                    max={planEnd}
                />
            </div>
            <div class="note">
                <input
                    value={note}
                    onChange={e => setNote(e.target.value)}
                />
            </div>
            <div class="editor-action">
                <button
                    type="button"
                    class="slim"
                    onClick={() => deleteDebtSchedule()}
                >
                    <FaTrash/>
                </button>
            </div>
        </div>
    );
}
