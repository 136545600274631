export const wait = () => new Promise((resolve) => setTimeout(resolve, 30));

export const NOW = {
    month: new Date().toLocaleString('default', { month: 'short' }).toLowerCase(),
    year: new Date().getFullYear(),
};

export function map_sum(arr, map_callback) {
    return arr.map(map_callback).reduce((a, b) => a + b, 0);
}

export function perScaled(value, per) {
    return Math.round(100 * value * perToMultiplier(per)) / 100;
}

function perToMultiplier(per) {
    if (per === 'two-weeks') {
        return 26/12;
    }
    if (per === 'year') {
        return 1/12;
    }
    if (per === 'month') {
        return 1;
    }
    return NaN;
}

export function monthyearToEpoch(monthyear) {
    if (monthyear.year < 1900) {
        return null;
    }

    return 12 * (monthyear.year - 1900) + monthToIndex(monthyear.month);
}

export function monthyearFromEpoch(epoch) {
    if (epoch < 0) {
        return null;
    }

    return {
        year: 1900 + Math.trunc(epoch/12),
        month: monthFromIndex(epoch % 12),
    };
}

function monthToIndex(month) {
    switch (month) {
        case 'jan':
            return 0;
        case 'feb':
            return 1;
        case 'mar':
            return 2;
        case 'apr':
            return 3;
        case 'may':
            return 4;
        case 'jun':
            return 5;
        case 'jul':
            return 6;
        case 'aug':
            return 7;
        case 'sep':
            return 8;
        case 'oct':
            return 9;
        case 'nov':
            return 10;
        case 'dec':
            return 11;
    }
}

function monthFromIndex(index) {
    if (index < 0) {
        console.error('got month index less than 0');
        return null;
    }

    if (11 < index) {
        console.error('got month index greater than 11');
        return null;
    }

    switch (index) {
        case 0:
            return 'jan';
        case 1:
            return 'feb';
        case 2:
            return 'mar';
        case 3:
            return 'apr';
        case 4:
            return 'may';
        case 5:
            return 'jun';
        case 6:
            return 'jul';
        case 7:
            return 'aug';
        case 8:
            return 'sep';
        case 9:
            return 'oct';
        case 10:
            return 'nov';
        case 11:
            return 'dec';
    }
}

export function monthyearWithinBounds(monthyear, min, max) {
    return monthyearToEpoch(min) <= monthyearToEpoch(monthyear)
        && monthyearToEpoch(monthyear) <= monthyearToEpoch(max);
}

export function monthyearComparator(x, y) {
    let epochX = monthyearToEpoch(x);
    let epochY = monthyearToEpoch(y);
    return epochX < epochY
        ? -1
        : epochY === epochX
            ? 0
            : 1;
}

/// given a date, resolve it to the MonthYear
export function resolveDate(events, date) {
    if ('MonthYear' in date) {
        return date.MonthYear;
    }

    if ('Event' in date && date.Event in events) {
        return events[date.Event].monthyear;
    }

    return null;
}
