import { useState } from 'react';

import { MonthYearSelector } from './monthyear-selector.js';
import { monthyearComparator, monthyearWithinBounds } from './util.js';

export function EventSelector({events, evt, setEvt, min, max}) {
    const [show, setShow] = useState(false);

    const eventMode = 'Event' in evt;
    const [eventEnabled, setEventEnabled] = useState(eventMode);

    // apparently this is how you have to filter for keys in an object
    const availableEvents = Object.entries(events)
        .filter(([id, evt]) => monthyearWithinBounds(evt.monthyear, min, max))
        .sort(([id1, evt1], [id2, evt2]) => monthyearComparator(evt1.monthyear, evt2.monthyear))
        .reduce((current, [id, evt]) => {
            return {
                ...current,
                [id]: evt,
            };
        }, {});

    // if we have an Event type, get its current MonthYear value for use as the default MonthYear
    // selection
    const monthyearOfEvent = evt?.Event
        && Object.entries(events).find(([id, val]) => id === evt.Event)[1].monthyear;

    const asDisplayed = eventMode
        ? events[evt.Event].name
        : getMonthyearString(evt?.MonthYear);

    return (
        <div class="event-selector">
            <button
                class="editor-button"
                onClick={() => setShow(!show)}
            >{show ? 'done' : asDisplayed}</button>
            {show && <div class="date-editor">
                <div
                    onMouseEnter={() => !eventMode && setEventEnabled(true)}
                    onMouseLeave={() => !eventMode && setEventEnabled(false)}
                >
                    <select
                        name="event"
                        value={eventMode ? evt.Event : 'nonevent'}
                        onChange={(e) => {
                            setEvt({ Event: e.target.value });
                            setShow(false);
                        }}
                        disabled={!eventEnabled}
                    >
                        <option value="nonevent" disabled hidden>event</option>
                        {Object.entries(availableEvents).map(([key, value]) => {
                            return <option
                                key={key}
                                value={key}
                            >{value.name}</option>
                        })}
                    </select>
                </div>
                <div class="divider">
                    <div class="rule"></div>
                    <div>or</div>
                    <div class="rule"></div>
                </div>
                <div
                    onMouseEnter={() => eventMode && setEventEnabled(false)}
                    onMouseLeave={() => eventMode && setEventEnabled(true)}
                >
                    <MonthYearSelector
                        monthyear={evt?.MonthYear}
                        setMonthyear={(monthyear) => setEvt({ MonthYear: monthyear })}
                        min={min}
                        max={max}
                        def={monthyearOfEvent}
                        disabled={eventEnabled}
                    />
                </div>
            </div>}
        </div>
    );
}

function getMonthyearString(monthyear) {
    let monthString = monthyear?.month?.toString();
    monthString = monthString.charAt(0).toUpperCase() + monthString.slice(1);
    return `${monthString} ${monthyear?.year}`;
}
