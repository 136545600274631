import React, { useState } from 'react';

import {
    FaDollarSign,
    FaMoneyCheck,
    FaUniversity,
    FaChartBar,
    FaUser,
    FaUserFriends,
} from "react-icons/fa";

import { AboutYou } from './about-you.js';
import { Accounts } from './accounts.js';
import { Debt } from './debt.js';
import { Expense } from './expense.js';
import { Income } from './income.js';

export function PlanEditor({
    events,
    birth,
    setBirth,
    start,
    retire,
    setRetire,
    end,
    filingStatus,
    setFilingStatus,
    income,
    setIncome,
    expense,
    setExpense,
    currentAccounts,
    setCurrentAccounts,
    debt,
    setDebt,
    planGraphData,
    baselineExpense,
    unaccountedForCashflowDestination,
    setUnaccountedForCashflowDestination,
    assets,
    setAssets,
}) {
    const [tab, setTab] = useState("about-you");

    return (
        <div id="plan-editor" class="raised">
            <PlanEditorNav tab={tab} setTab={setTab} filingStatus={filingStatus}/>
            <PlanEditorBody
                events={events}
                tab={tab}
                birth={birth}
                setBirth={setBirth}
                start={start}
                retire={retire}
                setRetire={setRetire}
                end={end}
                filingStatus={filingStatus}
                setFilingStatus={setFilingStatus}
                income={income}
                setIncome={setIncome}
                expense={expense}
                setExpense={setExpense}
                currentAccounts={currentAccounts}
                setCurrentAccounts={setCurrentAccounts}
                debt={debt}
                setDebt={setDebt}
                planGraphData={planGraphData}
                baselineExpense={baselineExpense}
                unaccountedForCashflowDestination={unaccountedForCashflowDestination}
                setUnaccountedForCashflowDestination={setUnaccountedForCashflowDestination}
                assets={assets}
                setAssets={setAssets}
            />
        </div>
    );
}

function PlanEditorNav({tab, setTab, filingStatus}) {
    return (
        <div id="plan-editor-nav">
            <button
                class={`${tab === "about-you" ? "selected" : ""}`}
                onClick={() => {
                    setTab("about-you")
                }}
            >
                {filingStatus === 'married'
                  ? <><FaUserFriends className={'editor-nav-icon'} size={18} />&nbsp;&nbsp;You</>
                  : <><FaUser className={'editor-nav-icon'} size={18}/>&nbsp;&nbsp;You</>
                }
            </button>
            <button
                class={`${tab === "income" ? "selected" : ""}`}
                onClick={() => setTab("income")}
            >
                <FaMoneyCheck className={'editor-nav-icon'} size={18}/>&nbsp;&nbsp;Income
            </button>
            <button
                class={`${tab === "expense" ? "selected" : ""}`}
                onClick={() => setTab("expense")}
            >
                <FaDollarSign className={'editor-nav-icon'} size={18}/>&nbsp;&nbsp;Expense
            </button>
            <button
                class={`${tab === "accounts" ? "selected" : ""}`}
                onClick={() => setTab("accounts")}
            >
                <FaChartBar className={'editor-nav-icon'} size={18}/>&nbsp;&nbsp;Accounts
            </button>
            <button
                class={`${tab === "debt" ? "selected" : ""}`}
                onClick={() => setTab("debt")}
            >
                <FaUniversity className={'editor-nav-icon'} size={18}/>&nbsp;&nbsp;Debt
            </button>
        </div>
    );
}

function PlanEditorBody({
    events,
    tab,
    birth,
    setBirth,
    start,
    retire,
    setRetire,
    end,
    filingStatus,
    setFilingStatus,
    income,
    setIncome,
    expense,
    setExpense,
    currentAccounts,
    setCurrentAccounts,
    debt,
    setDebt,
    planGraphData,
    baselineExpense,
    unaccountedForCashflowDestination,
    setUnaccountedForCashflowDestination,
    assets,
    setAssets,
}) {
    let netWorth = currentAccounts.filter(x => !x.deleted).map(x => x.value.current_balance)
        .concat(debt.filter(x => !x.deleted).map(sched => -sched.value.schedule.balance))
        .concat(assets.filter(x => !x.deleted).map(asset => asset.value))
        .reduce((acc, amount) => acc + amount, 0);

    return(
        <div id="plan-editor-container">
            <div id="plan-editor-body" class="scrolly">
                {tab === 'about-you' && <AboutYou
                    netWorth={netWorth}
                    events={events}
                    birth={birth}
                    setBirth={setBirth}
                    retire={retire}
                    setRetire={setRetire}
                    start={start}
                    filingStatus={filingStatus}
                    setFilingStatus={setFilingStatus}
                    unaccountedForCashflowDestination={unaccountedForCashflowDestination}
                    setUnaccountedForCashflowDestination={setUnaccountedForCashflowDestination}
                    assets={assets}
                    setAssets={setAssets}
                />}
                {tab === 'income' && <Income
                    events={events}
                    income={income}
                    setIncome={setIncome}
                    retire={retire}
                    planStart={start}
                    planEnd={end}
                />}
                {tab === 'expense' && <Expense
                    events={events}
                    expense={expense}
                    setExpense={setExpense}
                    planStart={start}
                    planEnd={end}
                    planGraphData={planGraphData}
                    baselineExpense={baselineExpense}
                />}
                {tab === 'accounts' && <Accounts
                    events={events}
                    currentAccounts={currentAccounts}
                    setCurrentAccounts={setCurrentAccounts}
                    planStart={start}
                    retire={retire}
                />}
                {tab === 'debt' && <Debt
                    events={events}
                    debt={debt}
                    setDebt={setDebt}
                    planStart={start}
                    planEnd={end}
                />}
            </div>
        </div>
    );
}
