import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import { defaultParams } from './default-params.js';
import domainName from './domain-name.js';
import { Core } from './core.js';
import { NOW } from './util.js';

// TODO: correct dates before setting `plan`
//       for example: set start date and enforce sane boundaries for birth and retire and end
export function PlanManager({page, signedIn}) {
    const [currentPlan, setCurrentPlan] = useState(null);
    const [plan, setPlan] = useState(null);

    let { planId } = useParams();

    // update run parameters from storage
    useEffect(() => {
        if (planId != null) {
            fetch(domainName + `/plans/${planId}`, {
                method: 'GET',
                mode: 'cors',
                credentials: 'include',
            })
            .then(response => response.text())
            .then(data => {
                if (data.length < 1) {
                    throw new Error(`could not get plan with ID ${currentPlan}`);
                }

                return data;
            })
            .then(data => atob(data))
            .then(data => JSON.parse(data))
            .then(data => setPlan(adjustDates(data)))
            .then(() => setCurrentPlan(planId))
            .catch(() => console.error('failed to fetch shared plan'));
        } else {
            if (signedIn) {
                // TODO if there is nothing in the plans table, check for legacy
                fetch(domainName + '/plans/user', {
                    method: 'GET',
                    mode: 'cors',
                    credentials: 'include',
                })
                .then(response => response.json())
                .then(data => {
                    if (data.length < 1) {
                        throw new Error('found no saved plans for user');
                    }

                    let {id, hex_data} = data[0]; // most-recently modified plan
                    setCurrentPlan(id);
                    return hex_data;
                })
                .then(data => atob(data))
                .then(data => JSON.parse(data))
                .then(data => setPlan(adjustDates(data)))
                .catch((error) => {
                    console.log('error caught: ' + error);
                    console.log('looking for legacy params...');
                    // TODO should handle different types of errors here
                    //      e.g., what should happen if the first request fails? should not fall
                    //      back to looking for legacy params
                    // try to get legacy parameters
                    fetch(domainName + '/params/current', {
                        method: 'GET',
                        mode: 'cors',
                        credentials: 'include',
                    })
                    .then(response => response.json())
                    .then(data => {
                        console.log('got legacy params');
                        setPlan(adjustDates(data));
                    })
                    .catch(() => {
                        setPlan(adjustDates(defaultParams));
                        console.log('Could not get latest run parameters (fell back to default)');
                    });
                });
            } else {
                setPlan(adjustDates(defaultParams));
            }
        }
    }, [currentPlan, planId, signedIn]);

    const storeParams = (current_params) => {
        // TODO the !planId is a sort of hacky way of checking to see we're not viewing a shared
        //      plan. there is probably a better way to accomplish this
        if (signedIn && !planId) {
            if (currentPlan) {
                fetch(`${domainName}/plans/${currentPlan}`, {
                    method: 'PUT',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'text/plain',
                    },
                    body: btoa(JSON.stringify(current_params)),
                    credentials: 'include',
                });
            } else {
                fetch(`${domainName}/plans`, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'text/plain',
                    },
                    body: btoa(JSON.stringify(current_params)),
                    credentials: 'include',
                })
                .then(response => response.text())
                .then((id) => setCurrentPlan(id));
            }
        } else {
            console.log('did not store params, cuz not logged in');
        }
    };

    if (plan == null) return 'loading plan...';

    return (
        <Core
            page={page}
            signedIn={signedIn}
            plan={plan}
            storeParams={storeParams}
        />
    );
}

// TODO Right now this just updates the `start` event, but eventually it should also do the sort of
//      checking that is done in the `updateBirth` and `updateRetire` functions in the `Core`
//      component, i.e., enforcing sane dates for `birth`, `retire` and `end`.
function adjustDates(plan) {
    let start_id = Object.entries(plan.events)
        .find(([key, value]) => value.name === 'start')[0];

    return {
        ...plan,
        events: {
            ...plan.events,
            [start_id]: {
                ...plan.events[start_id],
                monthyear: NOW,
            },
        }
    };
}
